export enum RouteNames {
    root = 'root',
    login = 'login',
    accountRequest = 'accountRequest',
    forgotPassword = 'forgotPassword',
    resetPassword = 'resetPassword',

    barcodeScanner = 'barcodeScanner',
    favouriteCustomerOrderList = 'favouriteCustomerOrderList',
    home = 'home',
    orderConfirmed = 'orderConfirmed',
    orderDetail = 'orderDetail',
    orderHistory = 'orderHistory',
    partner = 'partner',
    productActions = 'productActions',
    productDetail = 'productDetail',
    productPriceUpdates = 'priceUpdates',
    products = 'products',
    profile = 'profile',
    shoppingCart = 'shoppingCart',
    userDetail = 'userDetail',
    users = 'users',
}
