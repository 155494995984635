import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { OrderResponse, OrderRowRequest } from '@/core/http/services/orders/types'

export default class OrderRowService {
  constructor(private readonly service: ApiService) {
    //
  }

  private path(orderId: number, id: number | null = null): string {
    return `/api/orders/${orderId}/rows${id ? `/${id}` : ''}`
  }

  async store(orderId: number, request: OrderRowRequest): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.post(this.path(orderId), request)
  }

  async update(
    orderId: number,
    id: number,
    request: OrderRowRequest
  ): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.put(this.path(orderId, id), request)
  }

  async destroy(orderId: number, id: number): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.delete(this.path(orderId, id))
  }
}
