import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Sort, SortDirection } from '@/core/http/services/sorting/types'

export const useSortingStore = (key: string = 'default') =>
  defineStore(key + '-sorting', () => {
    const currentSort = ref<Sort>({
      key: null,
      direction: null,
    })

    function setSort(key: string, direction: SortDirection = 'asc') {
      if (currentSort.value.key === key) {
        currentSort.value.direction = currentSort.value.direction === 'asc' ? 'desc' : 'asc'
      } else {
        currentSort.value = {
          key,
          direction,
        }
      }
    }

    function isSortingOn(key: string): boolean {
      return currentSort.value.key === key
    }

    return { currentSort, setSort, isSortingOn }
  })()
