import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { ProductGroupsResponse } from '@/core/http/services/product-groups/types'

export default class ProductGroupService {
  private readonly frontendPath = 'api/frontend/product-groups'

  constructor(private readonly service: ApiService) {}

  public async index(): Promise<AxiosResponse<ProductGroupsResponse>> {
    return await this.service.client.get(this.frontendPath)
  }
}
