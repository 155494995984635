import { defineStore } from 'pinia'
import type { CustomerPages } from '@/core/http/services/cms/types'
import { ref } from 'vue'
import { apiService } from '@/core/http/services/ApiService'

export const useCmsStore = defineStore('cms', () => {
  const customerPages = ref<CustomerPages | null>(null)

  async function fetchCustomerPages(force: boolean = false): Promise<CustomerPages> {
    if (!customerPages.value || force) {
      const response = await apiService.cmsService.customerPages()
      customerPages.value = response.data.data
    }

    return customerPages.value
  }

  return { fetchCustomerPages, customerPages }
})
