import type { ProductResponse, ProductsResponse } from '@/core/http/services/products/types'
import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'
import type { ProductFilterParams } from '@/stores/product-filters/types'

export class ProductService {
  private readonly frontendPath = '/api/frontend/products'

  constructor(private readonly service: ApiService) {
    //
  }

  frontendIndex(
    page: number,
    filterParams: ProductFilterParams,
    sort: Sort | null = null,
  ): Promise<AxiosResponse<ProductsResponse>> {
    return this.service.client.get(this.frontendPath, {
      params: {
        page: page,
        product_group_ids: filterParams.productGroupIds,
        search: filterParams.search,
        ...filterParams.contentFilter,
        ...sortToQuery(sort),
      },
    })
  }

  show(id: number): Promise<AxiosResponse<ProductResponse>> {
    return this.service.client.get(`${this.frontendPath}/${id}`)
  }
}
