import type { ApiService } from '../ApiService'
import type {
  AccessTokenResponse,
  ForgotPasswordRequest,
  LoginRequest,
  RequestAccountRequest,
  ResetPasswordRequest,
} from '@/core/http/services/auth/types'
import type { AxiosResponse } from 'axios'
import Config from '@/config/Config'

export default class AuthService {
  constructor(private readonly apiService: ApiService) {
    //
  }

  public login(request: LoginRequest): Promise<AxiosResponse<AccessTokenResponse>> {
    return this.apiService.client.post('/oauth/token', {
      grant_type: 'password',
      username: request.username,
      password: request.password,
      client_id: Config.clientId,
      client_secret: Config.clientSecret,
    })
  }

  public forgotPassword(request: ForgotPasswordRequest): Promise<AxiosResponse> {
    return this.apiService.client.post('/api/forgot-password', request)
  }

  public resetPassword(request: ResetPasswordRequest): Promise<AxiosResponse> {
    return this.apiService.client.post('/api/reset-password', request)
  }

  public refreshToken(refreshToken: string): Promise<AxiosResponse<AccessTokenResponse>> {
    return this.apiService.client.post('/oauth/token', {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: Config.clientId,
      client_secret: Config.clientSecret,
    })
  }

  public requestAccount(request: RequestAccountRequest): Promise<AxiosResponse> {
    return this.apiService.client.post('/api/request-account', request)
  }
}
