import { RouteNames } from '@/router/RouteNames'
import { navbarGroups } from '@/router/navbarGroups'

const publicMeta = {
  public: true,
}

export type Route = {
  path: string
  name: string
  component: () => Promise<{}>
  meta?: {
    navbarGroup?: string
    public?: boolean
  }
}

export default <Route[]>[
  {
    path: '/',
    name: RouteNames.root,
  },
  {
    path: '/inloggen',
    name: RouteNames.login,
    component: () => import('@/views/auth/LoginView.vue'),
    meta: publicMeta,
  },
  {
    path: '/wachtwoord-vergeten',
    name: RouteNames.forgotPassword,
    component: () => import('@/views/auth/ForgotPasswordView.vue'),
    meta: publicMeta,
  },
  {
    path: '/herstel-wachtwoord',
    name: RouteNames.resetPassword,
    component: () => import('@/views/auth/ResetPasswordView.vue'),
    meta: publicMeta,
  },
  {
    path: '/account-aanvragen',
    name: RouteNames.accountRequest,
    component: () => import('@/views/auth/RequestAccount.vue'),
    meta: publicMeta,
  },
  {
    path: '/assortiment',
    name: RouteNames.products,
    component: () => import('@/views/products/ProductsView.vue'),
    meta: {
      navbarGroup: navbarGroups.assortment,
    },
  },
  {
    path: '/product/:id',
    name: RouteNames.productDetail,
    component: () => import('@/views/products/ProductDetailView.vue'),
    meta: {
      navbarGroup: navbarGroups.assortment,
    },
  },
  {
    path: '/bestelling',
    name: RouteNames.shoppingCart,
    component: () => import('@/views/orders/ShoppingCartView.vue'),
  },
  {
    path: '/gebruikers',
    name: RouteNames.users,
    component: () => import('@/views/users/UsersView.vue'),
    meta: {
      navbarGroup: navbarGroups.users,
    },
  },
  {
    path: '/users/edit/:id?',
    name: RouteNames.userDetail,
    component: () => import('@/views/users/UserDetailView.vue'),
    meta: {
      navbarGroup: navbarGroups.users,
    },
  },
  {
    path: '/profiel',
    name: RouteNames.profile,
    component: () => import('@/views/users/ProfileView.vue'),
  },
  {
    path: '/prijswijzigingen',
    name: RouteNames.productPriceUpdates,
    component: () => import('@/views/product-price-updates/ProductPriceUpdatesView.vue'),
    meta: {
      navbarGroup: navbarGroups.priceUpdates,
    },
  },
  {
    path: '/bestelling-bevestigd',
    name: RouteNames.orderConfirmed,
    component: () => import('@/views/orders/OrderConfirmedView.vue'),
  },
  {
    path: '/bestel-overzicht',
    name: RouteNames.orderHistory,
    component: () => import('@/views/orders/OrderHistoryView.vue'),
  },
  {
    path: '/bestelling/:id',
    name: RouteNames.orderDetail,
    component: () => import('@/views/orders/OrderDetailView.vue'),
  },
  {
    path: '/acties',
    name: RouteNames.productActions,
    component: () => import('@/views/products/actions/ProductActionsView.vue'),
    meta: {
      navbarGroup: navbarGroups.actions,
    },
  },
  {
    path: '/bestellijst',
    name: RouteNames.favouriteCustomerOrderList,
    component: () => import('@/views/products/customer-order-lists/CustomerOrderListView.vue'),
    meta: {
      navbarGroup: navbarGroups.favouriteOrderList,
    },
  },
  {
    path: '/scanner',
    name: RouteNames.barcodeScanner,
    component: () => import('@/views/barcode-scanner/BarcodeScannerView.vue'),
  },
  {
    path: '/home',
    name: RouteNames.home,
    component: () => import('@/views/home/HomeView.vue'),
    meta: {
      navbarGroup: navbarGroups.home,
    },
  },
  {
    path: '/partner/:slug',
    name: RouteNames.partner,
    component: () => import('@/views/partner/PartnerView.vue'),
    meta: {
      navbarGroup: navbarGroups.partner,
    },
  },
]
