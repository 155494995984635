import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { RepresentativeOrderRowsResponse } from '@/core/http/services/representative-order-rows/types'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'

export class RepresentativeOrderRowService {
  constructor(private readonly apiService: ApiService) {
    //
  }

  public index(sort: Sort | null = null): Promise<AxiosResponse<RepresentativeOrderRowsResponse>> {
    return this.apiService.client.get('/api/frontend/representative-order-rows', {
      params: {
        ...sortToQuery(sort),
      },
    })
  }
}
