import {defineStore} from 'pinia'
import {computed, ref, watch} from 'vue'
import type {User} from '@/core/http/services/users/types'
import {apiService} from '@/core/http/services/ApiService'
import {useRouter} from 'vue-router'
import {RouteNames} from '@/router/RouteNames'
import {useOrderStore} from '@/stores/orders/OrderStore'
import {$ResetPinia} from '@/pinia'
import {jwtDecode} from 'jwt-decode'
import {NotificationDisplay, useNotificationStore} from '@brixcrm/vue-frontend-shared'

export const useAuthStore = defineStore(
    'auth',
    () => {
        const user = ref<User | null>(null)
        const authToken = ref<string | null>(null)
        const refreshToken = ref<string | null>(null)
        const lastActiveAt = ref<number>(Date.now())
        const inactivityLimit = 30 * 60 * 1000 // 30 minutes
        const sessionStartedAt = ref<number | null>(null)
        const expiresNear = ref<number | null>(null)
        const onNearingExpirationTimeout = ref<ReturnType<typeof setTimeout>>()
        const isActingAsUser = ref<boolean>(false)
        const isAuthenticated = computed(() => authToken.value !== null)
        const errorMessage = ref<string | null>(null)
        const router = useRouter()
        let requestQueue: (() => void)[] = []
        let isRefreshing = false
        let inactivityCheckInterval: ReturnType<typeof setInterval> | null = null

        // Registering listeners and interceptors
        registerUserActivityListener()
        registerInterceptor()

        function checkInactivity() {
            const now = Date.now()
            const timeSinceLastActive = now - lastActiveAt.value

            if (timeSinceLastActive > inactivityLimit) {
                signOut()
            }
        }

        function resetUserActivity() {
            lastActiveAt.value = Date.now()
        }

        function startInactivityCheck() {
            if (inactivityCheckInterval) clearInterval(inactivityCheckInterval)
            inactivityCheckInterval = setInterval(checkInactivity, 5000) // check every 5 seconds
        }

        function stopInactivityCheck() {
            if (inactivityCheckInterval) clearInterval(inactivityCheckInterval)
        }

        function registerUserActivityListener() {
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                document.addEventListener('visibilitychange', resetUserActivity)
                document.addEventListener('mousemove', resetUserActivity)
                document.addEventListener('keydown', resetUserActivity)
                document.addEventListener('click', resetUserActivity)
            }
        }

        function removeUserActivityListener() {
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                document.removeEventListener('visibilitychange', resetUserActivity)
                document.removeEventListener('mousemove', resetUserActivity)
                document.removeEventListener('keydown', resetUserActivity)
                document.removeEventListener('click', resetUserActivity)
            }
        }

        watch(authToken, (newToken) => {
            apiService.setAuthToken(newToken)
        })

        watch(isAuthenticated, async (isAuthenticated) => {
            if (!isAuthenticated) {
                useNotificationStore().addSimpleNotification({
                    title: 'Uitgelogd',
                    body: 'Je bent uitgelogd. Tot de volgende keer!',
                    display: NotificationDisplay.INFO,
                    duration: 10000,
                })
            }
        })

        apiService.setErrorHandler(401, async (error) => {
            if (isRefreshing) {
                await new Promise<void>((resolve) => {
                    requestQueue.push(() => resolve());
                });

                // Retry with new token
                return apiService.client.request(error.config)
            }

            if (!refreshToken.value) {
                signOut()
            } else {
                await refreshTokenAndQueueRequests()
                // Retry after refreshing token
                return apiService.client.request(error.config)
            }
        })

        function signOut() {
            stopInactivityCheck()
            removeUserActivityListener()

            user.value = null
            authToken.value = null
            refreshToken.value = null
            sessionStartedAt.value = null
            expiresNear.value = null
            isActingAsUser.value = false
            errorMessage.value = null
            clearTimeout(onNearingExpirationTimeout.value)

            if (!router.currentRoute.value.meta?.public) {
                router.push({name: RouteNames.login})
            }
            $ResetPinia()
        }

        async function signIn(
            token: string,
            expires_in: number,
            refresh_token: string | null,
            initialize: boolean = true,
        ) {
            if (initialize) {
                $ResetPinia()
            }

            errorMessage.value = null
            authToken.value = token
            refreshToken.value = refresh_token

            const now = Date.now()
            sessionStartedAt.value = now

            // assume expiration half a minute early
            expiresNear.value = now + (expires_in - 30) * 1000

            await checkTokenExpirationOnLoad()
            registerNearingExpirationTimeout()

            apiService.setAuthToken(token)

            if (initialize) {
                await postSignIn()
            }
        }

        watch(isAuthenticated, (isAuthenticated) => {
            if (isAuthenticated) {
                resetUserActivity()
                startInactivityCheck()
            } else {
                stopInactivityCheck()
            }
        })

        async function postSignIn() {
            await loadUser()
            const orderStore = useOrderStore()
            await orderStore.getOpenOrder(true)
            orderStore.checkIfHasRepresentativeOrder()
            await router.replace({name: RouteNames.root})
        }

        function registerInterceptor() {
            apiService.client.interceptors.request.use(
                async (config) => {
                    if (config.url?.includes('/oauth/token')) {
                        return config
                    }

                    if (isRefreshing) {
                        await new Promise<void>((resolve) => {
                            requestQueue.push(() => resolve());
                        });
                    }

                    // Use new token directly
                    config.headers['Authorization'] = `Bearer ${authToken.value}`
                    return config
                },
                (error) => Promise.reject(error),
            )
        }

        /**
         * Keeps the session alive using the refresh_token, if the user is active.
         * 
         * - If the token is nearing expiration, it will refresh the token.
         * - If the token has expired, it will sign out.
         */
        async function keepSessionAlive() {
            const now = Date.now();

            if (expiresNear.value && expiresNear.value - now <= 30 * 1000) {
                if (refreshToken.value) {
                    await refreshTokenAndQueueRequests();
                } else {
                    signOut();
                }
            }
        }

        async function refreshTokenAndQueueRequests() {
            if (!refreshToken.value) {
                signOut();
                return;
            }

            isRefreshing = true;
            try {
                const response = await apiService.auth.refreshToken(refreshToken.value);

                // Update token directly
                authToken.value = response.data.access_token;
                apiService.setAuthToken(response.data.access_token);

                await signIn(
                    response.data.access_token,
                    response.data.expires_in,
                    response.data.refresh_token,
                    false,
                );

                requestQueue.forEach((callback) => callback());
                requestQueue = [];
            } catch (e) {
                signOut();
            } finally {
                isRefreshing = false;
            }
        }

        function registerNearingExpirationTimeout() {
            if (expiresNear.value) {
                clearTimeout(onNearingExpirationTimeout.value)
                onNearingExpirationTimeout.value = setTimeout(
                    () => {
                        keepSessionAlive()
                    },
                    expiresNear.value - Date.now() - 30 * 1000, // Refresh 30 seconds before expiration
                )
            }
        }

        async function checkTokenExpirationOnLoad() {
            if (authToken.value) {
                const jwtPayload = jwtDecode(authToken.value)
                const now = Date.now() / 1000 // Time in seconds

                if (jwtPayload.exp && jwtPayload.exp <= now) {
                    signOut() // Token has expired. Log out
                }
            }
        }

        async function actAsUser(id: number): Promise<void> {
            await apiService.user.actAsUser(id).then(async (response) => {
                const token = response.data.data.access_token,
                    jwtPayload = jwtDecode(token),
                    now = Date.now(),
                    expires_in = jwtPayload.exp ? jwtPayload.exp - now / 1000 : 30 * 60 // seconds

                // NOTE: ignoring for expiration deliberately
                await signIn(token, expires_in, null, true)
                isActingAsUser.value = true
            })
        }

        async function loadUser(): Promise<void> {
            await apiService.user.me().then((response) => {
                user.value = response.data.data
            })
        }

        const isAdmin = computed<boolean>(() => user.value?.role.type === 'admin')

        return {
            isAdmin,
            signOut,
            signIn,
            actAsUser,
            loadUser,
            authToken,
            expiresNear,
            keepSessionAlive,
            registerNearingExpirationTimeout,
            refreshToken,
            isActingAsUser,
            user,
            isAuthenticated,
            errorMessage,
            checkTokenExpirationOnLoad,
        }
    },
    {
        persist: true,
    },
)
