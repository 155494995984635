/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {
    faBoxOpen,
    faBarcode,
    faBars,
    faChevronCircleRight,
    faChevronCircleLeft,
} from '@fortawesome/free-solid-svg-icons'
import {faTableCells} from "@fortawesome/free-solid-svg-icons/faTableCells";

library.add(
    faBoxOpen,
    faBarcode,
    faChevronCircleRight,
    faChevronCircleLeft,
    faBars,
    faTableCells,
)
