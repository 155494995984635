import '@brixcrm/vue-frontend-shared/dist/style.css'
import './assets/style.css'
import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import { useAuthStore } from '@/stores/auth'
import {
  NotificationDisplay,
  useEasterEggStore,
  useNotificationStore,
} from '@brixcrm/vue-frontend-shared'
import { AxiosError } from 'axios'
import { pinia } from '@/pinia'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@/setup/fontawesome'
import * as Sentry from "@sentry/vue";
import Config from "@/config/Config";

import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'

import nl from 'dayjs/locale/nl'

dayjs.extend(localizedFormat)
dayjs.locale(nl)

const app = createApp(App)

Sentry.init({
  app,
  release: Config.release,
  environment: Config.environment,
  dsn: Config.sentryDsn,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(
        {
          maskAllText: false,
          blockAllMedia: false,
        }
    ),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/monnikbestellen\.staging\.brixcloud\.nl/,
    /^https:\/\/www\.monnikbestelportal\.nl/
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(pinia)
app.use(router)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')

const authStore = useAuthStore()
authStore.checkTokenExpirationOnLoad()
authStore.registerNearingExpirationTimeout()

app.config.errorHandler = (error) => {
  if (error instanceof AxiosError) {
    // leave it
  } else if (error instanceof Error) {
    if (isV3InfiniteLoadingError(error)) {
      // See: https://github.com/oumoussa98/vue3-infinite-loading/issues/75 for more info
      if (import.meta.env.DEV) {
        console.warn(
          'Suppressed error from `v3-infinite-loading`: Please check https://github.com/oumoussa98/vue3-infinite-loading/issues/75 for fixes',
        )
        console.error(error)
      }
      return
    }

    useNotificationStore().addSimpleNotification({
      title: 'Er is een fout opgetreden',
      body: error.message,
      display: NotificationDisplay.ERROR,
      duration: 5000,
    })
    throw error
  } else {
    // ???
    throw error
  }
}

const easterEggStore = useEasterEggStore()
easterEggStore.setKonamiCodeListener(document.body)
easterEggStore.setEasterEggHandler(() => {
  const elements = document.querySelectorAll('body')
  elements.forEach((element) => {
    element.classList.add('animate-drunken')
  })
})

type Filename = { fileName: string }

function hasFileName<T = unknown>(o: T): o is T & Filename {
  if (o === null && typeof o !== 'object') {
    return false
  }
  const c = o as Partial<Filename>

  return typeof c.fileName === 'string'
}

function isV3InfiniteLoadingError(error: Error): boolean {
  return (
    // Chrome
    error.message === "Cannot read properties of null (reading 'getBoundingClientRect')" ||
    // FireFox
    (hasFileName(error) && error.fileName.includes('v3-infinite-loading')) ||
    // Safari
    error.message.includes('getBoundingClientRect')
  )
}
