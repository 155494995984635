import { ApiService } from '../ApiService'
import type { AxiosResponse } from 'axios'
import type { ActAsUserResponse, PaginatedUsersResponse, UserRequest, UserResponse } from './types'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'

export default class UserService {
  constructor(private service: ApiService) {
    //
  }

  public index(
    page: number,
    query: string | null = null,
    sort: Sort | null = null,
  ): Promise<AxiosResponse<PaginatedUsersResponse>> {
    return this.service.client.get('/api/frontend/users', { params: { page: page, query: query, ...sortToQuery(sort) } })
  }

  public show(id: number): Promise<AxiosResponse<UserResponse>> {
    return this.service.client.get('/api/users/' + id)
  }

  async updateOrCreate(
    id: number | null,
    request: UserRequest
  ): Promise<AxiosResponse<UserResponse>> {
    if (id) {
      return this.update(id, request)
    } else {
      return this.store(request)
    }
  }

  public store(request: UserRequest): Promise<AxiosResponse<UserResponse>> {
    return this.service.client.post('/api/users', request)
  }

  public update(id: number, request: UserRequest): Promise<AxiosResponse<UserResponse>> {
    return this.service.client.put('/api/users/' + id, request)
  }

  public actAsUser(id: number): Promise<AxiosResponse<ActAsUserResponse>> {
    return this.service.client.post(`/api/frontend/users/${id}/act-as`)
  }

  public me(): Promise<AxiosResponse<UserResponse>> {
    return this.service.client.get('/api/frontend/users/me')
  }
}
