import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { ProductFilterResponse } from '@/core/http/services/product-filters/types'

export default class ProductFilterService {
  constructor(private readonly apiService: ApiService) {
    //
  }

  private path = '/api/frontend/product-filters'

  public async index(): Promise<AxiosResponse<ProductFilterResponse>> {
    return await this.apiService.client.get(this.path)
  }
}
