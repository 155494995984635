import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type {
  ConfirmOrderRequest,
  Order,
  OrderResponse,
  PaginatedOrdersResponse,
  UpdateOrderRequest,
} from '@/core/http/services/orders/types'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'

export default class OrderService {
  private readonly path = '/api/orders'
  private readonly frontendPath = '/api/frontend/orders'

  constructor(private readonly service: ApiService) {
    //
  }

  async getOpenOrder(sort: Sort | null = null): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.get(this.frontendPath + '/open', {
      params: {
        ...sortToQuery(sort),
      },
    })
  }

  async index(
    page: number,
    search: string | null = null,
  ): Promise<AxiosResponse<PaginatedOrdersResponse>> {
    return await this.service.client.get(this.frontendPath, {
      params: {
        page: page,
        search: search,
      },
    })
  }

  async update(id: number, request: UpdateOrderRequest): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.put(`${this.frontendPath}/${id}`, request)
  }

  async confirm(id: number, request: ConfirmOrderRequest): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.post(`${this.frontendPath}/${id}/confirm`, request)
  }

  async show(id: number): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.get(`${this.path}/${id}`)
  }

  async clear(id: number): Promise<AxiosResponse<OrderResponse>> {
    return await this.service.client.post(`${this.frontendPath}/${id}/clear`)
  }

  async downloadPdf(order: Order): Promise<void> {
    const response = await this.service.client.get(`${this.path}/${order.id}/pdf`, {
      responseType: 'blob',
    })

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']

    // make order id always have 4 digits
    let fileName = 'Order ' + order.id.toString().padStart(4, '0') + '.pdf'

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length > 1) fileName = fileNameMatch[1]
    }

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
}
