import type {ApiService} from "@/core/http/services/ApiService";
import type {AxiosResponse} from "axios";

export class CustomerProductGroupsService {
    constructor(private readonly apiService: ApiService) {
    }

    private frontendPath = '/api/frontend/customers-product-groups'

    public async index(): Promise<AxiosResponse<{ product_group_ids: number[] }>> {
        return this.apiService.client.get(this.frontendPath);
    }
}