import { defineStore } from 'pinia'
import type {
  ConfirmOrderRequest,
  Order,
  OrderResponse,
  OrderRowRequest,
} from '@/core/http/services/orders/types'
import { computed, ref, watch } from 'vue'
import { apiService } from '@/core/http/services/ApiService'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useSortingStore } from '@/stores/SortingStore'
import { sortToQuery } from '@/core/http/services/sorting/types'

const storeId = 'order'
export const useOrderStore = defineStore(storeId, () => {
  const openOrder = ref<Order | null>(null)
  const sortStore = useSortingStore(storeId)

  const showRepresentativeOrderAlert = ref(false)

  sortStore.setSort('product.name')

  watch(
    () => sortStore.currentSort,
    () => getOpenOrder(true),
    { deep: true },
  )

  const now = ref(dayjs())
  setInterval(() => (now.value = dayjs()), 60000)

  const minDeliveryDate = computed<Dayjs>(() => {
    const fivePM = dayjs().hour(17).minute(0)

    let daysToAdd = 0
    if (now.value.isAfter(fivePM)) {
      daysToAdd = 1
    }
    return now.value.clone().add(daysToAdd, 'day')
  })

  const deliveryDate = computed<Dayjs>(() => {
    if (openOrder.value?.delivery_at) {
      return dayjs(openOrder.value.delivery_at)
    } else {
      return minDeliveryDate.value
    }
  })

  async function setDeliveryDate(date: string) {
    const order = await getOpenOrder()
    await apiService.order
      .update(order.id, {
        comments: order.comments,
        delivery_at: date,
        ...sortToQuery(sortStore.currentSort),
      })
      .then((response) => (openOrder.value = response.data.data))
  }

  const mappedProductQuantities = computed(() => {
    const quantities = []
    for (const row of openOrder.value?.rows ?? []) {
      quantities[row.product_id] = row.quantity
    }

    return quantities
  })

  async function getOpenOrder(force: boolean = false): Promise<Order> {
    if (openOrder.value === null || force) {
      openOrder.value = (await apiService.order.getOpenOrder(sortStore.currentSort)).data.data
    }

    return openOrder.value
  }

  async function addRow(request: OrderRowRequest): Promise<Order> {
    return await apiService.orderRow.store((await getOpenOrder()).id, request).then((response) => {
      openOrder.value = response.data.data

      return response.data.data
    })
  }

  async function updateRow(id: number, request: OrderRowRequest): Promise<Order> {
    return await apiService.orderRow
      .update((await getOpenOrder()).id, id, {
        ...request,
        ...sortToQuery(sortStore.currentSort),
      })
      .then((response) => {
        openOrder.value = response.data.data

        return response.data.data
      })
  }

  async function deleteRow(id: number): Promise<Order> {
    return await apiService.orderRow.destroy((await getOpenOrder()).id, id).then((response) => {
      openOrder.value = response.data.data

      return response.data.data
    })
  }

  async function clear(): Promise<Order> {
    return await apiService.order.clear((await getOpenOrder()).id).then((response) => {
      openOrder.value = response.data.data

      return response.data.data
    })
  }

  async function setQuantity(productId: number, quantity: number): Promise<void> {
    const row = (await getOpenOrder())?.rows?.find((row) => row.product_id === productId)
    const request = { product_id: productId, quantity: quantity }
    if (row) {
      if (quantity === 0 || isNaN(quantity)) {
        await deleteRow(row.id)
      } else {
        await updateRow(row.id, request)
      }
    } else if (quantity > 0) {
      await addRow(request)
    }
  }

  async function confirm(request: ConfirmOrderRequest): Promise<OrderResponse> {
    const openOrder = await getOpenOrder()
    return await apiService.order.confirm(openOrder.id, request).then(async (res) => {
      // get new open order
      await getOpenOrder(true)

      return res.data
    })
  }

  function checkIfHasRepresentativeOrder(): void {
    apiService.representativeOrderRow.index().then((response) => {
      if (response.data.data.length > 0) {
        showRepresentativeOrderAlert.value = true
      }
    })
  }

  return {
    clear,
    confirm,
    checkIfHasRepresentativeOrder,
    deliveryDate,
    getOpenOrder,
    mappedProductQuantities,
    minDeliveryDate,
    openOrder,
    setDeliveryDate,
    setQuantity,
    showRepresentativeOrderAlert,
    sortStore,
  }
})
