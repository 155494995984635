import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type {
  CustomerOrderListRequest,
  CustomerOrderListResponse,
  PaginatedCustomerOrderLists,
} from '@/core/http/services/customer-order-lists/types'
import type { UserPreferencesFilter } from '@/core/http/services/product-action-prices/types'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'
import type { ProductFilterParams } from '@/stores/product-filters/types'

export class CustomerOrderListService {
  constructor(private readonly apiService: ApiService) {
    //
  }

  private path(customerId: number, id: number | null): string {
    return `/api/customers/${customerId}/order-lists` + (id ? `/${id}` : '')
  }

  private frontendPath = '/api/frontend/customer-order-lists'

  public async index(
    page: number,
    filter: UserPreferencesFilter,
    filterParams: ProductFilterParams,
    sort: Sort | null = null,
  ): Promise<AxiosResponse<PaginatedCustomerOrderLists>> {
    return await this.apiService.client.get(this.frontendPath, {
      params: {
        page: page,
        product_group_ids: filterParams.productGroupIds,
        search: filterParams.search,
        ...filter,
        ...filterParams.contentFilter,
        ...sortToQuery(sort),
      },
    })
  }

  public async update(
    id: number,
    customerId: number,
    data: CustomerOrderListRequest,
  ): Promise<AxiosResponse<CustomerOrderListResponse>> {
    return await this.apiService.client.put(this.path(customerId, id), data)
  }

  public async store(
    customerId: number,
    data: CustomerOrderListRequest,
  ): Promise<AxiosResponse<CustomerOrderListResponse>> {
    return await this.apiService.client.post(this.path(customerId, null), data)
  }
}
