export type SortDirection = 'asc' | 'desc';

export type Sort = {
  key: string | null,
  direction: SortDirection | null,
};

export function sortToQuery(sort: Sort | null): { sort?: string } {
  if (!sort?.key) {
    return {}
  }

  return { sort: `${sort.key}:${sort.direction || 'asc'}` }
}