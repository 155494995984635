import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { PaginatedActionPrices } from '@/core/http/services/product-action-prices/types'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'
import type { ProductFilterParams } from '@/stores/product-filters/types'

export class ProductActionPriceService {
  constructor(private readonly apiService: ApiService) {
    //
  }

  private path = '/api/frontend/action-prices'

  public index(
    page: number,
    filter: ProductFilterParams,
    sort: Sort | null = null,
  ): Promise<AxiosResponse<PaginatedActionPrices>> {
    return this.apiService.client.get(this.path, {
      params: {
        page: page,
        favourite: filter.favourite,
        currentOnly: filter.currentOnly,
        product_group_ids: filter.productGroupIds,
        search: filter.search,
        ...filter.contentFilter,
        ...sortToQuery(sort),
      },
    })
  }
}
