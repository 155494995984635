import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { CustomerOrderListProductGroupResponse } from '@/core/http/services/customer-order-lists/types'
import type { UserPreferencesFilter } from '@/core/http/services/product-action-prices/types'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'
import type { ProductFilterParams } from '@/stores/product-filters/types'

export class CustomerOrderListGroupService {
  constructor(private readonly apiService: ApiService) {
    //
  }

  private frontendPath = '/api/frontend/customer-order-list-groups'

  public async index(
    filter: UserPreferencesFilter,
    filterParams: ProductFilterParams,
    sort: Sort | null = null,
  ): Promise<AxiosResponse<CustomerOrderListProductGroupResponse>> {
    return await this.apiService.client.get(this.frontendPath, {
      params: {
        product_group_ids: filterParams.productGroupIds,
        search: filterParams.search,
        ...filter,
        ...filterParams.contentFilter,
        ...sortToQuery(sort),
      },
    })
  }
}
