import type { ApiService } from '../ApiService'
import type { CmsPage, CmsPartnerPage, CustomerPages } from '@/core/http/services/cms/types'
import type { AxiosResponse } from 'axios'
import type { DataResponse } from '@/core/http/services/types'

export default class CmsService {
  constructor(private readonly apiService: ApiService) {
    //
  }

  private readonly path = '/api/frontend/cms'

  public async customerPages(): Promise<AxiosResponse<DataResponse<CustomerPages>>> {
    return this.apiService.client.get(`${this.path}/customer-pages`)
  }

  public async landingPage(slug: string): Promise<AxiosResponse<DataResponse<CmsPage>>> {
    return this.apiService.client.get(`${this.path}/landing-page/${slug}`)
  }

  public async partnerPage(slug: string): Promise<AxiosResponse<DataResponse<CmsPartnerPage>>> {
    return this.apiService.client.get(`${this.path}/partner-page/${slug}`)
  }

  public async cmsPage(slug: string): Promise<AxiosResponse<DataResponse<CmsPage>>> {
    return this.apiService.client.get(`${this.path}/page/${slug}`)
  }
}
