import type { AxiosInstance } from 'axios'
import axios from 'axios'
import type { ErrorHandler } from './types'
import AuthService from '@/core/http/services/auth'
import UserService from '@/core/http/services/users/UserService'
import ProductGroupService from '@/core/http/services/product-groups'
import { ProductService } from '@/core/http/services/products/ProductService'
import OrderService from '@/core/http/services/orders/OrderService'
import OrderRowService from '@/core/http/services/orders/OrderRowService'
import { RoleService } from '@/core/http/services/roles/RoleService'
import { ProductPriceUpdateService } from '@/core/http/services/product-price-updates/ProductPriceUpdateService'
import { RepresentativeOrderRowService } from '@/core/http/services/representative-order-rows/RepresentativeOrderRowService'
import { ProductActionPriceService } from '@/core/http/services/product-action-prices/ProductActionPriceService'
import { CustomerOrderListService } from '@/core/http/services/customer-order-lists/CustomerOrderListService'
import ProductFilterService from '@/core/http/services/product-filters/ProductFilterService'
import Config from '@/config/Config'
import { CustomerOrderListGroupService } from '@/core/http/services/customer-order-lists/CustomerOrderListGroupService'
import CmsService from '@/core/http/services/cms/CmsService'
import {CustomerProductGroupsService} from "@/core/http/services/customer-order-lists/CustomerProductGroupsService";

export class ApiService {
  #errorCodeHandler: Map<number, ErrorHandler> = new Map()

  public readonly auth = new AuthService(this)
  public readonly user = new UserService(this)
  public readonly role = new RoleService(this)
  public readonly product = new ProductService(this)
  public readonly productGroups = new ProductGroupService(this)
  public readonly productPriceUpdate = new ProductPriceUpdateService(this)
  public readonly order = new OrderService(this)
  public readonly orderRow = new OrderRowService(this)
  public readonly representativeOrderRow = new RepresentativeOrderRowService(this)
  public readonly actionPrice = new ProductActionPriceService(this)
  public readonly customerOrderLists = new CustomerOrderListService(this)
  public readonly customerOrderListGroups = new CustomerOrderListGroupService(this)
  public readonly customerProductGroups = new CustomerProductGroupsService(this)
  public readonly productFilters = new ProductFilterService(this)
  public readonly cmsService = new CmsService(this)

  constructor(public client: AxiosInstance) {
    this.setErrorHandlers()
  }

  public static defaultApiService(axiosInstance: AxiosInstance, baseUrl: string) {
    axiosInstance.defaults.baseURL = baseUrl
    axiosInstance.defaults.headers.common['Content-Type'] = 'application/json'
    axiosInstance.defaults.headers.common['Accept'] = 'application/json'

    return new ApiService(axiosInstance)
  }

  public setAuthToken(token: string | null) {
    if (token === null) {
      delete this.client.defaults.headers.common['Authorization']
    } else {
      this.client.defaults.headers.common['Authorization'] = `Bearer ${token}`
    }
  }

  public setErrorHandler(httpCode: number, handler: ErrorHandler) {
    this.#errorCodeHandler.set(httpCode, handler)
  }

  private setErrorHandlers() {
    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        const status = error.response?.status
        const handler = status ? this.#errorCodeHandler.get(status) : undefined
        if (handler) {
          return await handler(error)
        }
        return Promise.reject(error)
      },
    )
  }
}

export const apiService = ApiService.defaultApiService(
  axios.create({ timeout: 10000 }),
  Config.backendUrl,
)
