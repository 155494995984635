export default class Config {
  public static get priceSortingLimit(): number {
    return parseInt(import.meta.env.VITE_BEST_PRICE_SORTING_LIMIT) ?? 500
  }

  public static get backendUrl(): string {
    return import.meta.env.VITE_BACKEND_URL
  }

  public static get clientId(): string {
    return import.meta.env.VITE_CLIENT_ID
  }

  public static get clientSecret(): string {
    return import.meta.env.VITE_CLIENT_SECRET
  }

  public static get easterEggDebugMode(): boolean {
    return import.meta.env.VITE_EASTER_EGG_DEBUG_MODE === 'true'
  }

  public static get scanditLicensyKey(): string {
    return import.meta.env.VITE_SCANDIT_LICENSE || ''
  }

  public static get sentryDsn(): string {
    return import.meta.env.VITE_SENTRY_DSN || ''
  }

  public static get release(): string {
    return import.meta.env.VITE_RELEASE || ''
  }

  public static get environment(): string {
    return import.meta.env.VITE_ENV || ''
  }
}
