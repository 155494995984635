import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { RolesResponse } from '@/core/http/services/roles/types'

export class RoleService {
  constructor(private readonly service: ApiService) {
    //
  }

  index(): Promise<AxiosResponse<RolesResponse>> {
    return this.service.client.get('/api/roles')
  }
}
