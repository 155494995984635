import type { ApiService } from '@/core/http/services/ApiService'
import type { AxiosResponse } from 'axios'
import type { PaginatedProductPriceUpdates } from '@/core/http/services/product-price-updates/types'
import type { Sort } from '@/core/http/services/sorting/types'
import { sortToQuery } from '@/core/http/services/sorting/types'
import type { ProductFilterParams } from '@/stores/product-filters/types'

export class ProductPriceUpdateService {
  constructor(private readonly service: ApiService) {
    //
  }

  public async index(
    page: number,
    filter: ProductFilterParams,
    sort: Sort | null = null,
  ): Promise<AxiosResponse<PaginatedProductPriceUpdates>> {
    return await this.service.client.get('/api/frontend/product-price-updates', {
      params: {
        page: page,
        favourite: filter.favourite,
        product_group_ids: filter.productGroupIds,
        search: filter.search,
        ...filter.contentFilter,
        ...sortToQuery(sort),
      },
    })
  }
}
